import React from 'react';
import 'assets/styles/pages/sidebar.scss';

import treeicon from 'assets/images/admin/tree-icon.png';
import breadcumbarrow from 'assets/images/admin/breadcumb-arrow-left.png';
import plannedby from 'assets/images/icons/planned-by.png';
import addimage from 'assets/images/register-plantation/add-image.png';
import malabarneelam from 'assets/images/register-plantation/malabar-neelam.png';
import trivandrumnursuery from 'assets/images/register-plantation/trinvandrum-nursuery.png';
import moreten from 'assets/images/admin/moreten.png';
import mulspeciestree from 'assets/images/register-plantation/multiple-species-tree.png';
import SEO from 'components/seo';
import Layout from 'layout';
import Plantationbreadcumb from 'components/register-plantation/plantationbread';

export default function Initiativeeditview() {
  return (
    <>
      <SEO title="Forests By Heartfulness | Add Saplings" />
      <Layout>
        <div>
          <Plantationbreadcumb backTo="/plantationregister" />
        </div>
        <div className="sidebar-content-wrapper">
          <div className="admin-breadcumb-item">
            <span>
              <img src={treeicon} alt="treeicon" />
            </span>

            <span>
              <img src={breadcumbarrow} alt="arrowicon" />
            </span>

            <h3> Green Kanha Initiative edit</h3>
          </div>
          <div className="white-iitiative-wrapper">
            <div className="display-item">
              <h3 className="title-item"> Green Kanha Initiative </h3>
            </div>
            <div className="display-date-time">
              <p> Tue, Apr 20, 2021, 12.45PM </p>
              <button className="btn btn-delete"> Delete </button>
            </div>
            <div className="dis-in-planed">
              <span className="dis-image-item">
                <img src={plannedby} alt="treeRightIconGreen" />
              </span>

              <h5 className="planed-text">Reg Id:</h5>
              <h5 className="date-calc-text"> #101975841320</h5>
            </div>
            <div className="dis-in-planed">
              <span className="dis-image-item">
                <img src={plannedby} alt="treeRightIconGreen" />
              </span>

              <h5 className="planed-text">Planted On:</h5>
              <h5 className="date-calc-text"> Tue, Apr 20, 2021</h5>
            </div>
            <div className="dis-in-planed">
              <span className="dis-image-item">
                <img src={plannedby} alt="treeRightIconGreen" />
              </span>

              <h5 className="planed-text">Planted By:</h5>
              <h5 className="date-calc-text"> Ram Kumar</h5>
            </div>
            <div className="dis-in-planed">
              <span className="dis-image-item">
                <img src={plannedby} alt="treeRightIconGreen" />
              </span>

              <h5 className="planed-text">Village/city </h5>
              <h5 className="date-calc-text"> Navalur, chennai</h5>
            </div>
            <div className="dis-in-planed">
              <span className="dis-image-item">
                <img src={plannedby} alt="treeRightIconGreen" />
              </span>

              <h5 className="planed-text">State: </h5>
              <h5 className="date-calc-text"> Tamil Nadu</h5>
            </div>
            <hr />

            <div className="tree-track-box tree-track-green-border">
              <h4 className="treenumber"> 7 trees</h4>
              <p className="tree-track-name">Tracking Id</p>
              <h5 className="tree-track-id">
                {" "}
                <b> #101975841320 </b>{" "}
              </h5>
              <div className="plant-wid">
                <span>
                  <p className="plant-type"> Plant Type </p>
                  <p>
                    {" "}
                    <b>Multiple </b>{" "}
                  </p>
                </span>
              </div>

              <div className="plant-wid">
                <span>
                  <p className="plant-type"> Species </p>
                  <p>
                    {" "}
                    <b> 5 Banayan 2 Neem </b>{" "}
                  </p>
                </span>
              </div>
            </div>
            <div className="tree-track-box">
              <h4 className="treenumber"> 7 trees</h4>
              <p className="tree-track-name">Track Id</p>
              <h5 className="tree-track-id">
                {" "}
                <b> #101975841320 </b>{" "}
              </h5>
              <div className="plant-wid">
                <span>
                  <p className="plant-type"> Plant Type </p>
                  <p>
                    {" "}
                    <b>Multiple </b>{" "}
                  </p>
                </span>
              </div>

              <div className="plant-wid">
                <span>
                  <p className="plant-type"> Species </p>
                  <p>
                    {" "}
                    <b> 5 Banayan 2 Neem </b>{" "}
                  </p>
                </span>
              </div>
            </div>

            <div className="tree-track-box">
              <h4 className="treenumber"> 7 trees</h4>
              <p className="tree-track-name">Tracking Id</p>
              <h5 className="tree-track-id">
                {" "}
                <b> #101975841320 </b>{" "}
              </h5>
              <div className="plant-wid">
                <span>
                  <p className="plant-type"> Plant Type </p>
                  <p>
                    {" "}
                    <b>Multiple </b>{" "}
                  </p>
                </span>
              </div>

              <div className="plant-wid">
                <span>
                  <p className="plant-type"> Species </p>
                  <p>
                    {" "}
                    <b> 5 Banayan 2 Neem </b>{" "}
                  </p>
                </span>
              </div>
            </div>

            <div className="addimage-wrapper">
              <h3 className="title-wrapper"> ADDED IMAGES </h3>

              <div className="dis-flex add-more-img-posi">
                <img src={addimage} className="img-fluid" alt="Add image" />

                <img
                  src={malabarneelam}
                  className="img-fluid"
                  alt="Add image"
                />

                <img
                  src={trivandrumnursuery}
                  className="img-fluid"
                  alt="Add image"
                />

                <img src={moreten} className="img-fluid" alt="Add image" />
              </div>
            </div>

            <div className="multiple-species-wrapper">
              <h3 className="title-wrapper">Multiple Species </h3>
              <div className="row">
                <div className="col-md-6 multiple-species-box-wrapper">
                  <div className="dis-flex icon-species-text">
                    <span className="mulspecies-tree">
                      <img src={mulspeciestree} alt="treeRightIconGreen" />
                    </span>

                    <div>
                      <h5 className="mul-title"> Your Banyan Tree </h5>
                      <p> 5 Trees </p>
                    </div>
                  </div>
                  <div className="list-species-item">
                    <div className="wid-50">
                      <h4 className="surving-item"> Species surviving count</h4>
                      <h4 className="surving-number-item"> 5</h4>
                      <h4 className="surving-item">
                        {" "}
                        Species not surviving count{" "}
                      </h4>
                      <h4 className="surving-number-item"> 0</h4>
                      <h4 className="surving-item">
                        {" "}
                        Species replaced with new{" "}
                      </h4>
                      <h4 className="surving-number-item"> 0</h4>
                    </div>
                    <div className="wid-50">
                      <h4 className="surving-item"> Height </h4>
                      <h4 className="surving-number-item"> 153.2cm </h4>
                      <h4 className="surving-item"> Girth(DBH) </h4>
                      <h4 className="surving-number-item"> 1.2cm </h4>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 multiple-species-box-wrapper">
                  <div className="dis-flex icon-species-text">
                    <span className="mulspecies-tree">
                      <img src={mulspeciestree} alt="treeRightIconGreen" />
                    </span>

                    <div>
                      <h5 className="mul-title"> Your Neem Tree </h5>
                      <p> 2 Trees </p>
                    </div>
                  </div>
                  <div className="list-species-item">
                    <div className="wid-50">
                      <h4 className="surving-item"> Species surviving count</h4>
                      <h4 className="surving-number-item"> 2</h4>
                      <h4 className="surving-item">
                        {" "}
                        Species not surviving count{" "}
                      </h4>
                      <h4 className="surving-number-item"> 0</h4>
                      <h4 className="surving-item">
                        {" "}
                        Species replaced with new{" "}
                      </h4>
                      <h4 className="surving-number-item"> 0</h4>
                    </div>
                    <div className="wid-50">
                      <h4 className="surving-item"> Height </h4>
                      <h4 className="surving-number-item"> 153.2cm </h4>
                      <h4 className="surving-item"> Girth(DBH) </h4>
                      <h4 className="surving-number-item"> 1.2cm </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-right mg-top20 display-button-order">
              <button className="btn btn-reject"> Rejected </button>
              <button className="btn btn-approve save-width posi-close-top">
                {" "}
                Approve{" "}
              </button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}